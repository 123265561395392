@import '../../scss/style.scss';

.header-area {
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    flex-wrap: wrap;
    @media #{$sm-layout} {
      padding: 15px 0;
    }
    @media #{$md-layout} {
      padding: 30px 0;
    }
    @media #{$lg-layout} {
      padding: 30px 0;
    }
    .header-hamburger {
      display: flex;
      align-items: center;
      .hamburger {
        margin-right: 27px;
      }
    }
    a {
      &.rn-btn {
        &.theme-color {
          border-color: $theme-color;
          color: $theme-color;
        }
        padding: 0 25px;
        height: 46px;
        display: inline-block;
        line-height: 42px;
        border: 2px solid #b1b4c1;
        border-radius: 6px;
        color: #fff;
        font-size: 14px;
        @extend %transition;
        position: relative;
        z-index: 2;
        @media #{$sm-layout} {
          padding: 0 11px;
          height: 39px;
          line-height: 33px;
          font-size: 12px;
        }
        @media #{$large-mobile} {
          height: 34px;
          line-height: 29px;
        }
        &::before {
          display: none;
        }
        &:hover {
          border-color: transparent;
          transform: translateY(-3px);
          color: #ffffff;
          border: 2px solid $theme-color;
          background: $theme-color;
          border-radius: 6px;
        }
        @media #{$md-layout} {
          margin-left: 10px;
          margin-right: 10px;
        }
      }
    }

    .logo {
      a {
        img {
          @media #{$sm-layout} {
            width: 120px;
          }
        }
      }
    }
  }
  &.color-white {
    a {
      &.rn-btn {
        border: 2px solid #ffffff;
        color: #ffffff;
      }
    }
  }

  &.color-black {
    a {
      &.rn-btn {
        border: 2px solid rgba(29, 29, 36, 0.5);
        color: #1d1d24;
      }
    }
  }

  // Header Fixed
  &.header {
    position: absolute;
    top: 0;
    z-index: 999;
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
    background: transparent;
    transition: 0.3s;

    .header-right {
      .header-btn {
        @media #{$lg-layout} {
          margin-left: 7px;
        }
      }
    }

    @media #{$lg-layout} {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media #{$md-layout} {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    @media #{$sm-layout} {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    .header-wrapper {
      padding: 20px 0;
      transition: 0.3s;
      @media #{$md-layout} {
        padding: 30px 0;
      }
      @media #{$sm-layout} {
        padding: 15px 0;
      }
      .logo {
        a {
          img {
            @media #{$sm-layout} {
              width: auto;
            }
          }
        }
      }

      ul {
        &.social-share {
          li {
            @media #{$sm-layout} {
              margin: 0;
            }
          }
        }
      }
      .logo {
        a {
          img {
            transition: 0.5s;
          }
        }
      }
      .mainmenunav {
        ul {
          &.mainmenu {
            > li {
              > a {
                position: relative;
                &::after {
                  position: absolute;
                  content: '';
                  left: 0;
                  width: 0;
                  height: 3px;
                  background: $theme-color;
                  transition: 0.3s;
                  bottom: 0;
                }
              }
              &.is-current {
                a {
                  color: $theme-color;
                  &::after {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.default-color {
      &.sticky {
        .mainmenunav {
          ul {
            &.mainmenu {
              > li {
                > a {
                  color: $heading-color;
                }
                &.is-current {
                  a {
                    color: $theme-color;
                    &::after {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    &.sticky {
      position: fixed;
      background: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      .header-wrapper {
        padding: 0px;
        a {
          &.rn-btn {
            color: $heading-color;
            border-color: $heading-color;
            &:hover {
              color: #ffffff;
              border-color: $theme-color;
            }
          }
        }
      }
      .logo {
        a {
          img {
            height: 50px;
          }
        }
      }
      .humburger-menu {
        span {
          &.text-white {
            color: $heading-color !important;
          }
        }
      }
    }
  }

  &.color-black {
    .humburger-menu {
      span {
        &.text-white {
          color: $heading-color !important;
        }
      }
    }
  }
}

.header-right {
  z-index: 99;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .header-btn {
    margin-left: 40px;
    margin-right: 10px;
  }
}

.header-area {
  .header-wrapper {
    .mainmenunav {
      @media #{$md-layout} {
        position: absolute;
      }
      @media #{$sm-layout} {
        position: absolute;
      }
      ul {
        &.mainmenu {
          @media #{$md-layout}, #{$sm-layout} {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            right: -101vw;
            z-index: 99;
            background: #ffffff;
            -webkit-transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
            -o-transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
            transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
            display: block;
            overflow-y: auto;
            padding: 105px 55px 55px 55px;
          }

          li {
            @media #{$md-layout} {
              margin: 0;
              padding: 10px 0;
            }
            @media #{$sm-layout} {
              margin: 0;
              padding: 10px 0;
            }
            &.has-droupdown {
              @media #{$md-layout} {
                position: relative;
              }
              @media #{$sm-layout} {
                position: relative;
              }
              > a {
                @media #{$sm-layout} {
                  position: relative;
                  &::after {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 7px 7px 0 7px;
                    border-color: #1f1f25 transparent transparent transparent;
                    position: absolute;
                    content: '';
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                  }
                  &.open {
                    color: $theme-color;
                    &::after {
                      width: 0;
                      height: 0;
                      border-style: solid;
                      border-width: 0 7px 7px 7px;
                      border-color: transparent transparent $theme-color transparent;
                    }
                  }
                }
                @media #{$md-layout} {
                  position: relative;
                  &::after {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 7px 7px 0 7px;
                    border-color: #1f1f25 transparent transparent transparent;
                    position: absolute;
                    content: '';
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                  }
                  &.open {
                    color: $theme-color;
                    &::after {
                      width: 0;
                      height: 0;
                      border-style: solid;
                      border-width: 0 7px 7px 7px;
                      border-color: transparent transparent $theme-color transparent;
                    }
                  }
                }
              }
            }
            a {
              @media #{$md-layout} {
                padding: 0;
                display: block;
              }
              @media #{$sm-layout} {
                padding: 0;
                display: block;
              }
            }
            ul {
              &.submenu {
                @media #{$md-layout} {
                  position: relative;
                  height: 0;
                  transition: 0.3s;
                  padding: 0;
                  opacity: 0;
                  visibility: hidden;
                  background: transparent;
                  box-shadow: inherit;
                }
                @media #{$sm-layout} {
                  position: relative;
                  height: 0;
                  transition: 0.3s;
                  padding: 0;
                  opacity: 0;
                  visibility: hidden;
                  background: transparent;
                  box-shadow: inherit;
                }
                li {
                  @media #{$md-layout} {
                    padding: 3px 0;
                  }
                  @media #{$sm-layout} {
                    padding: 3px 0;
                  }
                  a {
                    @media #{$md-layout} {
                      padding: 2px 10px;
                    }
                    @media #{$sm-layout} {
                      padding: 2px 10px;
                    }
                  }
                }
                &.active {
                  @media #{$md-layout} {
                    height: 100%;
                    padding: 12px 0;
                    opacity: 1;
                    visibility: visible;
                  }
                  @media #{$sm-layout} {
                    height: 100%;
                    padding: 12px 0;
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }
            }
            a {
              @media #{$md-layout} {
                color: $heading-color;
              }
              @media #{$sm-layout} {
                color: $heading-color;
              }
            }
          }
        }
      }
    }
    .close-menu {
      position: fixed;
      top: 12px;
      opacity: 0;
      visibility: hidden;
      right: 29px;
      z-index: -10;
    }
    &.menu-open {
      .close-menu {
        opacity: 1;
        visibility: visible;
        z-index: 9999;
        span {
          color: #000000;
          font-size: 30px;
          cursor: pointer;
        }
      }
      .mainmenunav {
        ul {
          &.mainmenu {
            @media #{$md-layout} {
              right: 0;
            }
            @media #{$sm-layout} {
              right: 0;
            }
            @media #{$large-mobile} {
              padding: 105px 20px 20px 20px;
            }
          }
        }
      }
    }
  }
}

/*------------------------
  Hamburger Menu  
---------------------------*/
.humburger-menu {
  span {
    cursor: pointer;
    @media #{$sm-layout} {
      font-size: 28px;
    }
  }
}
.header-wrapper.menu-open .humburger-menu span {
  opacity: 0;
}

/*====================
Mainmenu Area 
=====================*/

.mainmenunav {
  ul {
    &.mainmenu {
      text-align: center;
      display: flex;
      justify-content: flex-end;
      @extend %liststyle;
      flex-wrap: wrap;
      > li {
        margin: 0 15px;
        position: relative;
        > a {
          @extend %transition;
          color: #c6c9d8;
          font-size: 16px;
          font-weight: 500;
          padding: 20px 0;
          display: inline-block;
        }
        > ul {
          &.submenu {
            @extend %liststyle;
            min-width: 240px;
            height: auto;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 90;
            opacity: 0;
            visibility: hidden;
            background-color: #fff;
            box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
            text-align: left;
            @extend %transition;
            padding: 12px 0;
            border-radius: 4px;
            li {
              a {
                font-size: 16px;
                font-weight: 500;
                padding: 5px 20px;
                font-size: 14px;
                display: block;
                color: #1f1f25;
                margin: 0 10px;
                border-radius: 3px;
                @extend %transition;
              }
              &:hover {
                a {
                  color: $theme-color;
                  background: rgba(249, 0, 77, 0.07);
                }
              }
            }
          }
        }
        &:hover {
          > ul {
            &.submenu {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &:hover {
          > a {
            color: $theme-color;
          }
        }
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.humburger-menu {
  span {
    &.text-white {
      color: #c6c9d8 !important;
    }
  }
}

.color-white {
  .mainmenunav {
    ul {
      &.mainmenu {
        @extend %liststyle;
        > li {
          > a {
            color: #ffffff;
          }
          &:hover {
            > a {
              color: rgba(255, 255, 255, 0.6);
              @media #{$md-layout} {
                color: $theme-color;
              }
              @media #{$sm-layout} {
                color: $theme-color;
              }
            }
          }
        }
      }
    }
  }
}

.color-black {
  .mainmenunav {
    ul {
      &.mainmenu {
        @extend %liststyle;
        > li {
          > a {
            color: rgba(29, 29, 36, 1);
          }
          &:hover {
            > a {
              color: $theme-color;
            }
          }
        }
      }
    }
  }
}

/* Social icon Styles  */

ul {
  &.social-share {
    padding: 0;
    margin: 0;
    margin: -6px;
    li {
      margin: 6px;
      a {
        width: 40px;
        display: inline-block;
        height: 40px;
        border: 2px solid rgba(198, 201, 216, 0.75);
        line-height: 35px;
        color: rgba(198, 201, 216, 0.75);
        border-radius: 100%;
        text-align: center;
        font-size: 14px;
        @extend %transition;
      }
      &:hover {
        a {
          background: $theme-color;
          border-color: $theme-color;
          color: #ffffff;
          box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
          transform: translateY(-3px);
        }
      }
    }
    &.color-theme {
      li {
        a {
          border-color: $theme-color;
          color: $theme-color;
          &:hover {
            background: $theme-color;
            border-color: $theme-color;
            color: #ffffff;
          }
        }
      }
    }
    &.rn-lg-size {
      margin-top: 0 !important;
      li {
        a {
          width: 50px;
          height: 50px;
          line-height: 44px;
          font-size: 16px;
        }
      }
    }
    &.social-style--2 {
      li {
        a {
          border: 0 none;
          width: 30px;
          height: auto;
          text-align: left;
          line-height: inherit;
          font-size: 16px;
        }
        &:hover {
          a {
            background: transparent;
            box-shadow: none;
          }
        }
      }
      &.color-theme {
        li {
          a {
            color: $theme-color;
            &:hover {
              color: currentColor;
            }
          }
        }
      }
      &.color-black {
        li {
          a {
            color: #1d1d24;
            opacity: 0.8;
            &:hover {
              color: $theme-color;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
