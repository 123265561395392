@import '../../scss/style.scss';

.service {
  &.service_style {
    cursor: default;
    padding: 30px 35px;
    @extend %transition;
    @extend %posrelative;
    z-index: 2;
    border-radius: 10px;
    @media #{$laptop-device} {
      padding: 30px 28px;
    }
    @media #{$md-layout} {
      padding: 30px 25px;
    }
    @media #{$sm-layout} {
      padding: 30px 14px;
    }
    @media #{$large-mobile} {
      padding: 30px 18px;
      margin-top: 30px;
    }

    &::before {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      background-image: linear-gradient(to right, #f61b10, #ef0963);
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      @extend %transition;
      border-radius: 10px;
      @media #{$large-mobile} {
        opacity: 1;
        visibility: visible;
      }
    }
    .icon {
      font-size: 54px;
      font-weight: 400;
      margin-bottom: 23px;
      display: inline-flex;
      color: $theme-color;
      @extend %transition;
      @media #{$md-layout} {
        margin-bottom: 11px;
      }
      @media #{$large-mobile} {
        color: #ffffff;
      }
      svg {
        stroke-width: 1 !important;
      }
    }
    .content {
      h3 {
        &.title {
          margin-bottom: 19px;
          font-weight: 500;
          @extend %transition;
          @media #{$laptop-device} {
            font-size: 19px;
          }
          @media #{$sm-layout} {
            font-size: 20px;
          }
          @media #{$md-layout} {
            margin-bottom: 8px;
          }
          @media #{$large-mobile} {
            color: #ffffff;
          }
          a {
            color: inherit;
            @media #{$large-mobile} {
              color: #ffffff;
            }
          }
        }
      }
      p,
      h4 {
        @extend %transition;
        color: rgba(29, 29, 36, 0.75);
        @media #{$large-mobile} {
          color: #ffffff;
        }
      }
    }

    &:hover {
      box-shadow: 0 10px 25px 10px rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
      &::before {
        opacity: 1;
        visibility: visible;
      }
      .icon {
        color: #ffffff;
      }
      .content {
        h3 {
          &.title {
            @extend %textwhite;
          }
        }
        p,
        h4 {
          @extend %textwhite;
        }
      }
    }
  }
}

.service-one-wrapper {
  @media #{$md-layout} {
    margin: 0 -40px;
  }
}
