// Text Color
%themecolor {
  color: $theme-color;
}

%textwhite {
  color: #ffffff;
}

%liststyle {
  padding: 0;
  margin: 0;
  list-style: none;
}
.liststyle {
  padding: 0;
  margin: 0;
  list-style: none;
}

%transition {
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

%font {
  font-family: $body-font;
}

%posrelative {
  position: relative;
}

p:last-child {
  margin-bottom: 0;
}

.theme-gradient {
  background: linear-gradient(-259deg, #f81f01 0, #ee076e 100%);
}
.bg-theme-gradient {
  background: #f81f01;
  background: linear-gradient(145deg, #f81f01 0%, #ee076e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f81f01', endColorstr='#ee076e',GradientType=1 );
}

%theme-gradient {
  background: linear-gradient(-259deg, #f81f01 0, #ee076e 100%);
}
