.banner-container {
  position: relative;
  min-width: 101%;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-color: black;
  & video {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }
}
.banner {
  width: auto;
  max-width: 60%;
  margin: 0 auto 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
  height: 100vh;
  min-height: 35em;
  overflow: hidden;
  position: relative;
  text-align: center;
  animation: blur 2s;
}

.banner {
  h2 {
    animation: fadein 1.5s;
    display: inline-block;
    opacity: 1;
    padding: 0.35em 1em;
    position: relative;
    z-index: 1;
    font-weight: 800;
    letter-spacing: 0.225em;
    margin: 0 0 0.2em 0;
    text-transform: uppercase;
    color: #fff;
  }
  h2:before,
  h2:after {
    animation: expansion 0.8s;
    animation-fill-mode: forwards;
    animation-delay: 0.8s;
    background: #fff;
    content: '';
    display: block;
    height: 5px;
    position: absolute;
  }
  h2:before {
    top: 0;
    left: 0;
  }
  h2:after {
    bottom: 0;
    right: 0;
  }
}
.text-loop span {
  font-family: Yellowtail, sans-serif;

  animation: fadein 1s;
  animation-delay: 1.6s;
  animation-fill-mode: forwards;
  opacity: 0;
  font-weight: 900;
  font-size: 55px;
}

.textLoopsSkills {
  height: 100px;
  -webkit-transform: rotate(-5deg);
}

.inner {
  width: 60%;
  text-align: center;
}

.text-loop {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes expansion {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blur {
  0%,
  100% {
    -webkit-filter: blur(0px);
  }
  50% {
    -webkit-filter: blur(10px);
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .textLoopsSkills {
    height: 50px;
  }

  .banner h2 {
    font-size: 25px;
  }
  .text-loop span {
    font-size: 25px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .banner h2 {
    font-size: 50px;
  }
}
