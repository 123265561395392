@import '../../scss/style.scss';

.portfolio {
  position: relative;
  @extend %transition;
  min-height: 600px;
  width: 100%;
  padding-top: 35%;

  @media #{$laptop-device} {
    min-height: 500px;
  }
  @media #{$lg-layout} {
    min-height: 500px;
  }
  @media #{$md-layout} {
    min-height: 450px;
  }
  @media #{$sm-layout} {
    min-height: 400px;
  }
  .thumbnail-inner {
    transition: transform 0.28s ease;
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;

    &::before {
      background-color: #f6004c;
      background-image: linear-gradient(#f6004c 10%, #000000 100%);
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      opacity: 0;
      @extend %transition;
    }
  }
  .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    background-color: #0a0a0a;
    transform: scale(1.13) translateZ(0);
    backface-visibility: hidden;
    transition: transform 0.28s ease;
    z-index: 4;

    &::after {
      background-color: rgba(45, 45, 45, 0.35);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      content: ' ';
      z-index: 1;
      display: block;
      border-radius: 5px;
      background-color: rgba(25, 25, 25, 0.37);
      @extend %transition;
    }
  }

  .bg-blr-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-color: #0a0a0a;
    @extend %transition;
    transform: translateY(38px);
    opacity: 0;
    z-index: 1;
  }

  .content {
    position: absolute;
    bottom: 40px;
    left: 40px;
    right: 40px;
    max-width: 100% !important;
    z-index: 10;
    padding: 0;
    @media #{$lg-layout} {
      left: 20px;
      right: 20px;
    }
    @media #{$md-layout} {
      left: 20px;
      right: 20px;
    }
    @media #{$sm-layout} {
      left: 20px;
      right: 20px;
    }
    .inner {
      p {
        color: #c6c9d8;
        font-size: 14px;
      }
      h4 {
        font-size: 24px;
        line-height: 36px;
        a {
          color: #ffffff;
        }
      }
      .portfolio-button {
        @extend %transition;
        margin-top: 35px;
        transition: 0.7s;
        @media #{$large-mobile} {
          margin-top: 25px;
        }
        a {
          &.rn-btn {
            color: #ffffff;
            border-color: rgba(255, 255, 255, 0.3);
            &:hover {
              border-color: $theme-color;
            }
          }
        }
      }
    }
  }

  &.portfolio-section {
    .thumbnail {
      &.image-1 {
        background-image: url(../../assets/images/projects/fakeDataGenerator/4.jpg);
      }

      &.image-2 {
        background-image: url(../../assets/images/projects/activityTimer/6.png);
      }

      &.image-3 {
        background-image: url(../../assets/images/projects/autoApplet/2.png);
      }
      &.image-4 {
        background-image: url(../../assets/images/projects/website/2.jpg);
      }
    }
    .bg-blr-image {
      background-image: url(../../assets/images/projects/activityTimer/4.png);
      &.image-1 {
        background-image: url(../../assets/images/projects/fakeDataGenerator/4.jpg);
      }

      &.image-2 {
        background-image: url(../../assets/images/projects/activityTimer/6.png);
      }

      &.image-3 {
        background-image: url(../../assets/images/projects/autoApplet/2.png);
      }
      &.image-4 {
        background-image: url(../../assets/images/projects/website/2.jpg);
      }
    }
  }

  &.text-center {
    .content {
      position: absolute;
      bottom: 40px;
      left: 40px;
      max-width: 80% !important;
      z-index: 10;
      padding: 0;
      right: 40px;
      margin: 0 auto;
      @media #{$lg-layout} {
        left: 20px;
        right: 20px;
      }
      @media #{$md-layout} {
        left: 15px;
        right: 15px;
      }
      @media #{$sm-layout} {
        left: 10px;
        right: 10px;
      }
    }
  }
  &:hover {
    .thumbnail-inner {
      transform: scale(1.08) translateZ(0);
      &::before {
        opacity: 0.85;
      }
    }
    .thumbnail {
      transform: scale(1) translateZ(0);
      &::after {
        background-color: rgba(25, 25, 25, 0.24);
      }
    }
    .bg-blr-image {
      opacity: 0;
      z-index: 9;
    }
  }
  &.portfolio-style--2 {
    .inner {
      margin: 0 20px;
    }
  }
}

.portfolio-carousel-inner {
  .slick-list {
    @media #{$md-layout} {
      margin-left: 15px;
      margin-right: 15px;
    }
    @media #{$lg-layout} {
      margin-left: 15px;
      margin-right: 15px;
    }
    @media #{$sm-layout} {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .slick-slide {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: -10px;

    @media #{$lg-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$md-layout} {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media #{$sm-layout} {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.portfolio-carousel-inner {
  .slick-list {
    overflow: visible;
  }
}

.portfolio-carousel-inner,
.rn-slick-dot {
  .slick-dots {
    bottom: -60px;
    @media #{$large-mobile} {
      bottom: -50px;
    }
    li {
      width: 30px;
      margin: 0;
      opacity: 0.35;
      position: relative;

      button {
        &::before {
          display: none;
        }
        &::after {
          background: #333;
          width: 100%;
          display: block;
          content: '';
          position: absolute;
          left: 0;
          width: 6px;
          height: 6px;
          @extend %transition;
          border-radius: 50%;
          margin-left: 11px;
          background-color: transparent;
          box-shadow: inset 0 0 0 5px #000;
        }
      }
      &.slick-active {
        opacity: 1;
        button {
          &::after {
            -webkit-transform: scale(2);
            transform: scale(2);
            box-shadow: inset 0 0 0 1px $theme-color;
            background-color: transparent;
            background-color: transparent !important;
          }
        }
      }
    }
  }
  // Dot Light
  &.dot-light {
    .slick-dots {
      li {
        button {
          &::after {
            background: #ffffff;
            box-shadow: inset 0 0 0 5px #ffffff;
          }
        }
        &.slick-active {
          opacity: 1;
          button {
            &::after {
              -webkit-transform: scale(2);
              transform: scale(2);
              box-shadow: inset 0 0 0 1px $theme-color;
              background-color: transparent;
              background-color: transparent !important;
            }
          }
        }
      }
    }
  }
}

.portfolio-slick-activation {
  position: relative;
  button {
    &.slick-arrow {
      position: absolute;
      z-index: 3;
      background: transparent;
      font-size: 20px;
      width: 253px;
      height: 101%;

      @media #{$lg-layout} {
        width: 136px;
      }

      @media #{$md-layout} {
        width: 100px;
      }

      @media #{$sm-layout} {
        width: 100px;
        display: none !important;
      }

      &::before {
        line-height: 1;
        opacity: 0.75;
        font-size: 30px;
        position: relative;
        top: -14px;
        transition: 0.5s;
      }
    }
  }
}

a,
button {
  &.rn-btn {
    padding: 0 23px;
    height: 40px;
    display: inline-block;
    line-height: 34px;
    border: 2px solid $theme-color;
    border-radius: 4px;
    font-size: 14px;
    position: relative;
    z-index: 2;
    color: $theme-color;
    letter-spacing: 0.2px;
    @extend %transition;
    text-transform: uppercase;
    &:hover {
      border: 2px solid $theme-color;
      transform: translateY(-3px);
      color: #ffffff;
      background: $theme-color;
    }
  }
}
