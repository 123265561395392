/*==============================
 *  Utilities
=================================*/
/*====================================
    Google Font  
===================================*/
@import url('https://fonts.googleapis.com/css?family=Yellowtail:400,400i,500,600,700,800,900|Poppins:300,400,500,600,700,700i&display=swap');

/*----------------------------------------
font-family: 'Poppins', sans-serif;

-------------------------------------*/

.hidden {
  display: none;
}
.fix {
  overflow: hidden;
}

.clearfix:before,
.clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

.wrapper {
  position: relative;
}

/* Define Colors */
.theme-color {
  color: $theme-color;
}

.theme-gradient {
  background: rgb(248, 31, 1);
  background: -moz-linear-gradient(145deg, rgba(248, 31, 1, 1) 0%, rgba(238, 7, 110, 1) 100%);
  background: -webkit-linear-gradient(145deg, rgba(248, 31, 1, 1) 0%, rgba(238, 7, 110, 1) 100%);
  background: linear-gradient(145deg, rgba(248, 31, 1, 1) 0%, rgba(238, 7, 110, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f81f01', endColorstr='#ee076e',GradientType=1 );
}

.rn-text-white {
  color: #c6c9d8 !important;
}

/*===============================
    Background Color 
=================================*/

@include config-bg-colors(
  'bg_color--',
  '1' $white,
  '2' $theme-color,
  '3' #f8f9fc,
  '4' #1a1b1f,
  '5' #f8f9fc,
  '6' #000000,
  '7' #101010
);

p {
  font-size: 18px;
  line-height: 30px;
  @media #{$sm-layout} {
    font-size: 16px !important;
    line-height: 28px !important;
  }
}

p:last-child {
  margin-bottom: 0;
}

.row--35 {
  margin-left: -35px;
  margin-right: -35px;

  // Responsive
  @media #{$laptop-device} {
    margin-left: -25px;
    margin-right: -25px;
  }

  @media #{$lg-layout} {
    margin-left: -25px;
    margin-right: -25px;
  }

  @media #{$md-layout} {
    margin-left: -15px;
    margin-right: -15px;
  }

  @media #{$sm-layout} {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  & > [class*='col'],
  & > [class*='col-'] {
    padding-left: 35px;
    padding-right: 35px;

    // Responsive
    @media #{$laptop-device} {
      padding-left: 25px;
      padding-right: 25px;
    }

    @media #{$lg-layout} {
      padding-left: 25px;
      padding-right: 25px;
    }

    @media #{$md-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    @media #{$sm-layout} {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}

/*===========================
    Input Placeholder
=============================*/
input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

/*=============================
	Overlay styles 
==============================*/

[data-overlay],
[data-black-overlay],
[data-white-overlay] {
  position: relative;
  z-index: 1;
}

[data-overlay] > div,
[data-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-white-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 1;
}

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

[data-overlay]:before {
  background-color: $theme-color;
}

[data-black-overlay]:before {
  background-color: #00010c;
}

[data-white-overlay]:before {
  background-color: #b4abab;
}

@for $i from 1 through 10 {
  [data-overlay='#{$i}']:before,
  [data-black-overlay='#{$i}']:before,
  [data-white-overlay='#{$i}']:before {
    opacity: #{$i * 0.1};
  }
}

.thumb,
.thumbnail {
  img {
    border-radius: 6px;
  }
}
