@import './scss/style.scss';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

html,
body {
  background-color: #ffffff;
  color: #383838;
  font-family: $body-font !important;
}

.bodyLocked {
  overflow: hidden;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #383838;
}

section table {
  background-color: #ffffff;
}

::-moz-selection {
  background: #444444;
  color: #dddddd;
}

::selection {
  background: #444444;
  color: #dddddd;
}

a,
a:hover,
a:active {
  color: #0000ff;
}

hr {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ffffff;
}

div.navbar-inner,
.navbar .nav li ul {
  background: #dddddd;
  color: #222222;
}

a.btn-navbar {
  background: #dddddd;
  color: #222222;
}

.navbar .nav li a {
  color: #222222;
  text-shadow: 1px 1px 0px #eeeeee;
}

.navbar .nav li a:hover,
.navbar .nav li.active a {
  text-shadow: none;
}

.navbar .nav > li a {
  color: #444444;
}

.navbar .nav > li a:hover,
a.btn-navbar:hover {
  background: #444444;
  color: #dddddd;
}

.navbar .nav .active > a,
.navbar .nav .active > a:hover,
a.btn-navbar:active {
  background-color: #444444;
  color: #dddddd;
}

.navbar .nav li ul li a:hover {
  background: #444444;
  color: #dddddd;
}

.navbar .nav li ul li a:active {
  background: #444444;
  color: #dddddd;
}

.btn-primary {
  background-image: -moz-linear-gradient(top, #0088cc, #0044cc);
  background-image: -ms-linear-gradient(top, #0088cc, #0044cc);
  background-image: -webkit-gradient(linear, 0 0, 0 #0088cc, from(#dddddd), to(#0044cc));
  background-image: -webkit-linear-gradient(top, #0088cc, #0044cc);
  background-image: -o-linear-gradient(top, #0088cc, #0044cc);
  background-image: linear-gradient(top, #0088cc, #0044cc);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0088CC', endColorstr='#0044CC', GradientType=0);
  border-color: #0044cc #0044cc #bfbfbf;
  color: #ffffff;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.btn-primary.disabled,
.btn-primary[disabled] {
  background-color: #0044cc;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
