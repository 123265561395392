/*=========================
    Section Separation 
==========================*/

@for $i from 1 through 40 {
  .ptb--#{5 * $i} {
    padding: 5px * $i 0;
  }
  .plr--#{5 * $i} {
    padding: 0 5px * $i;
  }
  .pl--#{5 * $i} {
    padding-left: 5px * $i;
  }
  .pr--#{5 * $i} {
    padding-right: 5px * $i;
  }
  .mt--#{5 * $i} {
    margin-top: 5px * $i !important;
  }
  .mb--#{5 * $i} {
    margin-bottom: 5px * $i;
  }
  .ml--#{5 * $i} {
    margin-left: 5px * $i;
  }
}

.ptb--120 {
  @media #{$sm-layout} {
    padding: 60px 0 !important;
  }
  @media #{$md-layout} {
    padding: 80px 0 !important;
  }
}

@media #{$md-layout} {
  @for $i from 1 through 20 {
    .mt_md--#{5 * $i} {
      margin-top: 5px * $i !important;
    }

    .mb_md--#{5 * $i} {
      margin-bottom: 5px * $i !important;
    }
  }
}

@media #{$sm-layout} {
  @for $i from 1 through 20 {
    .plr_sm--#{5 * $i} {
      padding: 0 5px * $i !important;
    }

    .mt_sm--#{5 * $i} {
      margin-top: 5px * $i !important;
    }

    .mb_sm--#{5 * $i} {
      margin-bottom: 5px * $i !important;
    }
  }
}
