@import '../../scss/style.scss';
.contact-form-styles {
  padding: 120px 0;
  label {
    display: block;
    margin-bottom: 0;
  }

  input,
  textarea {
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: all 0.3s ease;
    height: 50px;
    line-height: 46px;
    margin-bottom: 20px;
    outline: none;
    color: #1f1f25;
    font-size: 15px;
    letter-spacing: 0.1px;
    &:focus {
      border-color: $theme-color;
    }
  }
  textarea {
    height: 120px;
  }
}

input,
textarea {
  @extend %transition;
}

button,
a {
  &.rn-button-style {
    color: #c6c9d8;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: 2px solid #b1b4c1;
    padding: 15px 40px;
    border-radius: 6px;
    display: inline-block;
    font-weight: 500;
    transition: 0.3s;
    @media #{$sm-layout} {
      padding: 9px 25px;
    }
    &.btn-primary-color {
      &:hover {
        border: 2px solid $theme-color;
        background: $theme-color;
        color: #ffffff;
      }
    }
    &.btn-solid {
      border: 2px solid $theme-color;
      background: $theme-color;
      color: #ffffff;
    }
    &:hover {
      background: transparent;
      color: $theme-color;
      box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
    }
  }
}
